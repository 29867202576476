var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-menu',{attrs:{"offset-y":"","content-class":"gst-city-dropdown__menu","close-on-content-click":false,"disabled":_vm.disabled},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticClass:"gst-city-dropdown d-inline-block",attrs:{"tabindex":"0"},on:{"click":_vm.onClickDo,"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.onClickDo($event)}}},on),[_c('span',{staticClass:"u-mouse-pointer gst-city-dropdown__target d-inline-block",class:{
                    'tertiary--text': !_vm.menuOpened,
                    'primary--text': _vm.menuOpened
                },attrs:{"data-test-id":_vm.$attrs['data-test-id']}},[_vm._v(" "+_vm._s(_vm.value && _vm.value.name ? _vm.$options.filters.city( _vm.value, { country: true } ) : _vm.$t('placeholder'))+" ")]),_c('i',{staticClass:"u-mouse-pointer gst-icon-svg",class:{
                    'gst-icon-svg--active u-rotate-180': _vm.menuOpened
                }},[_c('BaseIcon',{staticClass:"gst-dropdown-icon-svg",attrs:{"symbol-id":"icons--dropdown_arrow"}})],1)])]}}]),model:{value:(_vm.menuOpened),callback:function ($$v) {_vm.menuOpened=$$v},expression:"menuOpened"}},[_c('v-list',{staticClass:"gst-city-dropdown__list"},[(_vm.clearable)?_c('v-list-item',{staticClass:"gst-city-dropdown__list-item gst-city-dropdown__reset",attrs:{"disabled":!_vm.value,"ripple":false},on:{"click":function($event){return _vm.select( null )}}},[_c('v-list-item-title',[_c('div',[_vm._v(_vm._s(_vm.$t('_common:terms.reset')))])])],1):_vm._e(),(_vm.clearable)?_c('v-divider'):_vm._e(),_c('v-list-item-group',[(_vm.clearable)?_c('v-list-item',{staticClass:"gst-city-dropdown__list-item",class:{ 'selected': !_vm.value },on:{"click":function($event){return _vm.select( null )}}},[_c('v-list-item-title',[_c('div',[_vm._v(_vm._s(_vm.$t('labels.clear')))])])],1):_vm._e(),(_vm.loading)?_c('DataLoading',{staticClass:"mt-1 mb-2",attrs:{"width":2}}):[(!_vm.items.length)?_c('p',{staticClass:"gst-city-dropdown__no-data"},[_vm._v(" "+_vm._s(_vm.$t('messages.noData'))+" ")]):_vm._e(),_c('SearchCityList',{staticClass:"gst-city-dropdown__search-list",attrs:{"items":_vm.items,"selected":_vm.value},on:{"select":_vm.select}})]],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }