import { render, staticRenderFns } from "./AttractionBanner.vue?vue&type=template&id=52344cca&scoped=true&"
import script from "./AttractionBanner.vue?vue&type=script&lang=js&"
export * from "./AttractionBanner.vue?vue&type=script&lang=js&"
import style0 from "./AttractionBanner.vue?vue&type=style&index=0&id=52344cca&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52344cca",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VFlex } from 'vuetify/lib/components/VGrid';
installComponents(component, {VFlex})
